<template>

</template>

<script>
import Message from "@/js/Message";
import Global from "../js/global";
export default {
  name: "Show",
  data() {
    return {};
  },
  mounted() {
    if ( Global.progress === 100){
      Global.Func.openSpecialEffects();
    }
  },
  unmounted() {
    Message.closeSpecialEffect();
  }
};
</script>

<style scoped>

</style>
